.russel-section-desktop {
    height: 60vh;
    background-size: cover;
    background-position: center;
    position: relative;
}

.scalable {
    transition: all 400ms ease-out;
    transform: scale(1);
}

.scalable.hidden {
    transform: scale(0);
}

.russel-section-desktop .banner {
    height: 400px;
    display: block;
}

.russel-section-desktop .character {
    height:300px;
    position: absolute;
    bottom: 250px;
    /*bottom:-40%;*/
    left: -500px;
    transition: all 3s;
}
.russel-section-desktop .character.comeIn {
    left: 75vw;
}

.russel-section-mobile {
    height: 80vh;
    background-size: cover;
    background-position: center;
    position: relative;
    padding-top: 20px;
}

.russel-section-mobile .banner {
    width: 100%;
    margin-bottom: 20px;
}

.russel-section-mobile .character {
    width: 40vw;
    position: absolute;
    left: -500px;
    transition: all 3s;
}

.russel-section-mobile .character.comeIn {
    left: 100px;
}

.russel-section-mobile .character img {
    width: 100%;
}