.mobile-only {
    display: none;
}

.mobile-only-flex {
    display: none
}

a {
    text-decoration: none;
}

@media (max-width: 576px) {
    .mobile-only {
        display: block;
    }

    .mobile-only-flex {
        display: flex;
    }

    .no-mobile {
        display: none;
    }

}
.slick-arrow::before {
    font-size: 30px !important
}