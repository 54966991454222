.itemWrapper {
    transition: 200ms;
}

.project-text {
    opacity: 0;
    transition: 200ms;
    font-weight: bold;
    font-size: 16px;
}

.itemWrapper:hover {
    scale: 1.1;
    transition: 200ms;
}

.itemWrapper:hover .project-text {
    opacity: 1 !important;
    transition: 200ms;
}
.slick-dots li button::before {
font-size: 15px;
}
.slick-dots li button:hover::before {
    color: white !important;
}

.slick-dots li.slick-active button:before {
    color: white !important;
}

.team-image {
    transition: 200ms;
    cursor: pointer;
}

.team-image:hover {
    scale: 1.1;
    transition: 200ms;
}